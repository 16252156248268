import { render, staticRenderFns } from "./AddRolesDialog.vue?vue&type=template&id=f529193e&scoped=true&"
import script from "./AddRolesDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AddRolesDialog.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./AddRolesDialog.vue?vue&type=style&index=0&id=f529193e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f529193e",
  null
  
)

export default component.exports