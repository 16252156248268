/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(0);

const generateStudentStats = (
  numberOfStudents: number,
  totalProblemsAssigned: number
) => {
  const stats = [];

  for (let i = 0; i < numberOfStudents; i++) {
    const completed = faker.number.int({ min: 0, max: totalProblemsAssigned });

    stats.push({
      studentXref: `user${i + 50}`,
      totalProblemsAssigned,
      totalProblemsGraded: completed,
      totalProblemsCompleted: completed,
      totalScoreOnProblems: faker.number.float({
        min: 0,
        max: completed,
        precision: 0.000001,
      }),
    });
  }

  return stats;
};

export default function (server: Server): void {
  // Refer to Kendall Hunt Illustrative Math Grade 2
  server.create('teacherCurriculumStat', {
    teacherXref: 'C9rUPIqxW7q2x9oLpSDG',
    classStats: [
      // Teacher 1, Class 1 Stats
      {
        classXref: '1',
        moduleStats: [
          // Teacher 1 Class 1, Module 1 Stats
          {
            folderId: 17,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 100,
                sumOfStudentAverageScores: 8.0,
                numStudentsScored: 10,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 100,
                totalNumProblemsCompleted: 80,
                sumOfStudentAverageScores: 9,
                numStudentsScored: 10,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 1, Class 1, Lesson 1
              {
                folderId: 26,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 20,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 150,
                    sumOfStudentAverageScores: 8.5,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 20),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: undefined,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 15,
                    sumOfStudentAverageScores: 9,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 20),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 7.5,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 2),
                  },
                ],
              },
              // Teacher 1, Class 1, Module 1, Lesson 2
              {
                folderId: 27,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: null,
                    totalNumProblemsAssigned: 120,
                    totalNumProblemsCompleted: 100,
                    sumOfStudentAverageScores: 9.5,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    // totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 8,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 20),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 14,
                    sumOfStudentAverageScores: 7.5,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 1),
                  },
                ],
              }, // End Teacher 1's class 1 module 1 lesson 2 stats
              // Teacher 1, Class 1, Module 1, Lesson 3
              {
                folderId: 28,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['9JnFP7A4K853S3d9HsF2'],
                    totalNumUniqueProblemsAssigned: 7,
                    totalNumProblemsAssigned: 140,
                    totalNumProblemsCompleted: 115,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 30,
                    sumOfStudentAverageScores: 7,
                    numStudentsScored: 10,
                    studentAssesmentStats: generateStudentStats(3, 2),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 19,
                    studentAssesmentStats: generateStudentStats(3, 1),
                  },
                ],
              }, // End Teacher 1's class 1 module 1 lesson 3 stats
            ], // End Teacher 1's class 1 module 1 lesson stats list
          }, // End Teacher 1's class 1 module 1 stats
          // Teacher 1, Class 1, Module 2 Stats
          {
            folderId: 18,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 180,
                sumOfStudentAverageScores: 10,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 195,
                sumOfStudentAverageScores: 18,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 1, Class 1, Module 2 Lesson 1
              {
                folderId: 50,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 8,
                    totalNumProblemsAssigned: 160,
                    totalNumProblemsCompleted: 150,
                    sumOfStudentAverageScores: 10,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 5,
                    totalNumProblemsAssigned: 100,
                    totalNumProblemsCompleted: 90,
                    sumOfStudentAverageScores: 10.5,
                    numStudentsScored: 15,
                    studentAssesmentStats: generateStudentStats(3, 5),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              },
              // Teacher 1, Class 1, Module 2 Lesson 2
              {
                folderId: 51,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 185,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 45,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                    studentAssesmentStats: generateStudentStats(3, 3),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 19,
                    studentAssesmentStats: generateStudentStats(3, 1),
                  },
                ],
              }, // End Teacher 1's class 1 module 2 lesson 2 stats
              // Teacher 1, Class 1, Module 2, Lesson 3
              {
                folderId: 52,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 8,
                    totalNumProblemsAssigned: 160,
                    totalNumProblemsCompleted: 140,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 50,
                    sumOfStudentAverageScores: 9,
                    numStudentsScored: 10,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 18.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 1's class 1 module 2 lesson 3 stats
            ], // End Teacher 1's class 1 module 2 lesson stats list
          }, // End Teacher 1's class 1 module 2 stats
          // Teacher 1, Class 1, Module 3 Stats
          {
            folderId: 19,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 180,
                sumOfStudentAverageScores: 10,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 195,
                sumOfStudentAverageScores: 18,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 1, Class 1, Module 3 Lesson 1
              {
                folderId: 60,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 5,
                    totalNumProblemsAssigned: 100,
                    totalNumProblemsCompleted: 90,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 4,
                    totalNumProblemsAssigned: 80,
                    totalNumProblemsCompleted: 65,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 38,
                    sumOfStudentAverageScores: 11,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              },
              // Teacher 1, Class 1, Module 3 Lesson 2
              {
                folderId: 61,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 135,
                    sumOfStudentAverageScores: 11,
                    numStudentsScored: 15,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 55,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 12,
                    sumOfStudentAverageScores: 9,
                    numStudentsScored: 12,
                  },
                ],
              }, // End Teacher 1's class 1 module 3 lesson 2 stats
              // Teacher 1, Class 1, Module 3, Lesson 3
              {
                folderId: 62,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 145,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 33,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 17,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 28,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 15,
                  },
                ],
              }, // End Teacher 1's class 1 module 3 lesson 3 stats
            ], // End Teacher 1's class 1 module 3 lesson stats list
          }, // End Teacher 1's class 1 module 3 stats
        ], // End Teacher 1's class 1 module stats list
      }, // End teacher 1's class 1 stats
      {
        classXref: '2',
        moduleStats: [
          // Teacher 1 Class 2, Module 1 Stats
          {
            folderId: 17,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 100,
                sumOfStudentAverageScores: 8.0,
                numStudentsScored: 10,
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 5,
                totalNumProblemsAssigned: 100,
                totalNumProblemsCompleted: 80,
                sumOfStudentAverageScores: 9,
                numStudentsScored: 10,
              },
            ],
            lessonStats: [
              // Teacher 1, Class 2, Module 1, Lesson 1
              {
                folderId: 26,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 12,
                    totalNumProblemsAssigned: 240,
                    totalNumProblemsCompleted: 190,
                    sumOfStudentAverageScores: 12,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 7,
                    numStudentsScored: 10,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 30,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              },
              // Teacher 1, Class 2, Module 1, Lesson 2
              {
                folderId: 27,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 8,
                    totalNumProblemsAssigned: 160,
                    totalNumProblemsCompleted: 110,
                    sumOfStudentAverageScores: 11,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 40,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 1's class 2 module 1 lesson 2 stats
              // Teacher 1, Class 2, Module 1, Lesson 3
              {
                folderId: 28,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 8,
                    totalNumProblemsAssigned: 160,
                    totalNumProblemsCompleted: 130,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                  },
                ],
              }, // End Teacher 1's class 2 module 1 lesson 3 stats
            ], // End Teacher 1's class 2 module 1 lesson stats list
          }, // End Teacher 1's class 2 module 1 stats
          // Teacher 1, Class 2, Module 2 Stats
          {
            folderId: 18,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 11,
                totalNumProblemsAssigned: 220,
                totalNumProblemsCompleted: 160,
                sumOfStudentAverageScores: 14,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 185,
                sumOfStudentAverageScores: 17,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 1, Class 2, Module 2 Lesson 1
              {
                folderId: 50,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 150,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 5,
                    totalNumProblemsAssigned: 100,
                    totalNumProblemsCompleted: 95,
                    sumOfStudentAverageScores: 14.5,
                    numStudentsScored: 15,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 19,
                  },
                ],
              },
              // Teacher 1, Class 2, Module 2 Lesson 2
              {
                folderId: 51,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 195,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 50,
                    sumOfStudentAverageScores: 13,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 14,
                    sumOfStudentAverageScores: 12,
                    numStudentsScored: 14,
                  },
                ],
              }, // End Teacher 1's class 2 module 2 lesson 2 stats
              // Teacher 1, Class 2, Module 2, Lesson 3
              {
                folderId: 52,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 8,
                    totalNumProblemsAssigned: 160,
                    totalNumProblemsCompleted: 130,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 47,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 32,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 1's class 2 module 2 lesson 3 stats
            ], // End Teacher 1's class 2 module 2 lesson stats list
          }, // End Teacher 1's class 2 module 2 stats
          // Teacher 1, Class 2, Module 3 Stats
          {
            folderId: 19,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 150,
                sumOfStudentAverageScores: 14,
                numStudentsScored: 17,
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 185,
                sumOfStudentAverageScores: 16,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 1, Class 2, Module 3, Lesson 1
              {
                folderId: 60,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 120,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 15,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 18.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              },
              // Teacher 1, Class 2, Module 3 Lesson 2
              {
                folderId: 61,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 165,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 17,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 45,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 15,
                    sumOfStudentAverageScores: 14.5,
                    numStudentsScored: 15,
                  },
                ],
              }, // End Teacher 2's class 1 module 3 lesson 2 stats
              // Teacher 1, Class 1, Module 3, Lesson 3
              {
                folderId: 62,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 165,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 38,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 36,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 1's class 2 module 3 lesson 3 stats
            ], // End Teacher 1's class 2 module 3 lesson stats list
          }, // End Teacher 1's class 2 module 3 stats
        ], // End Teacher 1's class 2 module stats list
      }, // End teacher 1's class 2 stats
    ], // End teacher 1 class stats list
  } as object); // End teacher 1's CurriculumStats
  server.create('teacherCurriculumStat', {
    teacherXref: 'wNmUOMlw2AIqZ8bKUF9',
    classStats: [
      // Teacher 2, Class 1 Stats
      {
        classXref: '3',
        moduleStats: [
          // Teacher 2 Class 1, Module 1 Stats
          {
            folderId: 17,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 150,
                sumOfStudentAverageScores: 12,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 5,
                totalNumProblemsAssigned: 100,
                totalNumProblemsCompleted: 80,
                sumOfStudentAverageScores: 18,
                numStudentsScored: 19,
              },
            ],
            lessonStats: [
              // Teacher 2, Class 1, Module 1, Lesson 1
              {
                folderId: 26,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 6,
                    totalNumProblemsAssigned: 120,
                    totalNumProblemsCompleted: 100,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 7,
                    totalNumProblemsAssigned: 140,
                    totalNumProblemsCompleted: 110,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 18,
                  },
                ],
              },
              // Teacher 2, Class 1, Module 1, Lesson 2
              {
                folderId: 27,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 160,
                    sumOfStudentAverageScores: 20,
                    numStudentsScored: 22,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 48,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 18,
                  },
                ],
              }, // End Teacher 2's class 1 module 1 lesson 2 stats
              // Teacher 2, Class 1, Module 1, Lesson 3
              {
                folderId: 28,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 175,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 10,
                    sumOfStudentAverageScores: 9.5,
                    numStudentsScored: 10,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 15,
                    sumOfStudentAverageScores: 14.5,
                    numStudentsScored: 15,
                  },
                ],
              }, // End Teacher 2's class 1 module 1 lesson 3 stats
            ], // End Teacher 2's class 1 module 1 lesson stats list
          }, // End Teacher 2's class 1 module 1 stats
          // Teacher 2, Class 1, Module 2 Stats
          {
            folderId: 18,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 7,
                totalNumProblemsAssigned: 140,
                totalNumProblemsCompleted: 120,
                sumOfStudentAverageScores: 17,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 9,
                totalNumProblemsAssigned: 180,
                totalNumProblemsCompleted: 175,
                sumOfStudentAverageScores: 19,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 2, Class 1, Module 2 Lesson 1
              {
                folderId: 50,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 165,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 4,
                    totalNumProblemsAssigned: 80,
                    totalNumProblemsCompleted: 70,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 14,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 14,
                  },
                ],
              },
              // Teacher 2, Class 1, Module 2 Lesson 2
              {
                folderId: 51,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 5,
                    totalNumProblemsAssigned: 100,
                    totalNumProblemsCompleted: 85,
                    sumOfStudentAverageScores: 12,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 14,
                    sumOfStudentAverageScores: 11,
                    numStudentsScored: 14,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 11,
                    sumOfStudentAverageScores: 10,
                    numStudentsScored: 11,
                  },
                ],
              }, // End Teacher 2's class 1 module 2 lesson 2 stats
              // Teacher 2, Class 1, Module 2, Lesson 3
              {
                folderId: 52,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 10,
                    totalNumProblemsAssigned: 200,
                    totalNumProblemsCompleted: 180,
                    sumOfStudentAverageScores: 16.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 10,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 12,
                    sumOfStudentAverageScores: 11.5,
                    numStudentsScored: 12,
                  },
                ],
              }, // End Teacher 2's class 1 module 2 lesson 3 stats
            ], // End Teacher 2's class 1 module 2 lesson stats list
          }, // End Teacher 2's class 1 module 2 stats
          // Teacher 2, Class 1, Module 3 Stats
          {
            folderId: 19,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 140,
                sumOfStudentAverageScores: 12.5,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 195,
                sumOfStudentAverageScores: 18.5,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 2, Class 1, Module 3 Lesson 1
              {
                folderId: 60,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 6,
                    totalNumProblemsAssigned: 120,
                    totalNumProblemsCompleted: 90,
                    sumOfStudentAverageScores: 15.5,
                    numStudentsScored: 21,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 5,
                    totalNumProblemsAssigned: 100,
                    totalNumProblemsCompleted: 65,
                    sumOfStudentAverageScores: 12,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 22,
                    sumOfStudentAverageScores: 7,
                    numStudentsScored: 12,
                  },
                ],
              },
              // Teacher 2, Class 1, Module 3 Lesson 2
              {
                folderId: 61,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 7,
                    totalNumProblemsAssigned: 140,
                    totalNumProblemsCompleted: 135,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 40,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 33,
                    sumOfStudentAverageScores: 15.5,
                    numStudentsScored: 17,
                  },
                ],
              }, // End Teacher 2's class 1 module 3 lesson 2 stats
              // Teacher 2, Class 1, Module 3, Lesson 3
              {
                folderId: 62,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 6,
                    totalNumProblemsAssigned: 120,
                    totalNumProblemsCompleted: 105,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 2's class 1 module 3 lesson 3 stats
            ], // End Teacher 2's class 1 module 3 lesson stats list
          }, // End Teacher 2's class 1 module 3 stats
        ], // End Teacher 2's class 1 module stats list
      }, // End Teacher 2's class 1 stats
      {
        classXref: '7',
        moduleStats: [
          // Teacher 2 Class 2, Module 1 Stats
          {
            folderId: 17,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 100,
                sumOfStudentAverageScores: 17,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 5,
                totalNumProblemsAssigned: 100,
                totalNumProblemsCompleted: 100,
                sumOfStudentAverageScores: 19,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 2, Class 2, Module 1, Lesson 1
              {
                folderId: 26,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 4,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 36,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 18,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              },
              // Teacher 2, Class 2, Module 1, Lesson 2
              {
                folderId: 27,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 55,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 18.5,
                    numStudentsScored: 19,
                  },
                ],
              }, // End Teacher 2's class 2 module 1 lesson 2 stats
              // Teacher 2, Class 2, Module 1, Lesson 3
              {
                folderId: 28,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 59,
                    sumOfStudentAverageScores: 19,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 34,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 16.5,
                    numStudentsScored: 19,
                  },
                ],
              }, // End Teacher 2's class 2 module 1 lesson 3 stats
            ], // End Teacher 2's class 2 module 1 lesson stats list
          }, // End Teacher 2's class 2 module 1 stats
          // Teacher 2, Class 2, Module 2 Stats
          {
            folderId: 18,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 200,
                sumOfStudentAverageScores: 13,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 205,
                sumOfStudentAverageScores: 18.5,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 2, Class 2, Module 2 Lesson 1
              {
                folderId: 50,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 7,
                    totalNumProblemsAssigned: 140,
                    totalNumProblemsCompleted: 140,
                    sumOfStudentAverageScores: 15.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 19,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 33,
                    sumOfStudentAverageScores: 11,
                    numStudentsScored: 15,
                  },
                ],
              },
              // Teacher 2, Class 2, Module 2 Lesson 2
              {
                folderId: 51,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 145,
                    sumOfStudentAverageScores: 13,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 40,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 34,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 18,
                  },
                ],
              }, // End Teacher 2's class 2 module 2 lesson 2 stats
              // Teacher 2, Class 2, Module 2, Lesson 3
              {
                folderId: 52,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 30,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 27,
                    sumOfStudentAverageScores: 14.5,
                    numStudentsScored: 15,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 15,
                    numStudentsScored: 19,
                  },
                ],
              }, // End Teacher 2's class 2 module 2 lesson 3 stats
            ], // End Teacher 2's class 2 module 2 lesson stats list
          }, // End Teacher 2's class 2 module 2 stats
          // Teacher 2, Class 2, Module 3 Stats
          {
            folderId: 19,
            assessmentTypeStats: [
              {
                problemSetType: 'Readiness A',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 200,
                sumOfStudentAverageScores: 14.8,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
              {
                problemSetType: 'Post-Test',
                assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                totalNumUniqueProblemsAssigned: 10,
                totalNumProblemsAssigned: 200,
                totalNumProblemsCompleted: 200,
                sumOfStudentAverageScores: 16.5,
                numStudentsScored: 20,
                studentAssesmentStats: generateStudentStats(3, 10),
              },
            ],
            lessonStats: [
              // Teacher 2, Class 2, Module 3 Lesson 1
              {
                folderId: 60,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 4,
                    totalNumProblemsAssigned: 800,
                    totalNumProblemsCompleted: 70,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 35,
                    sumOfStudentAverageScores: 18.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 19,
                    sumOfStudentAverageScores: 16.5,
                    numStudentsScored: 19,
                  },
                ],
              },
              // Teacher 2, Class 2, Module 3 Lesson 2
              {
                folderId: 61,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 9,
                    totalNumProblemsAssigned: 180,
                    totalNumProblemsCompleted: 165,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 18,
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 22,
                    sumOfStudentAverageScores: 7,
                    numStudentsScored: 12,
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 18,
                    sumOfStudentAverageScores: 16,
                    numStudentsScored: 18,
                  },
                ],
              }, // End Teacher 2's class 1 module 3 lesson 2 stats
              // Teacher 2, Class 2, Module 3, Lesson 3
              {
                folderId: 62,
                problemSetTypeStats: [
                  {
                    problemSetType: 'Student Facing Task',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 3,
                    totalNumProblemsAssigned: 60,
                    totalNumProblemsCompleted: 48,
                    sumOfStudentAverageScores: 14,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Practice Problems',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 2,
                    totalNumProblemsAssigned: 40,
                    totalNumProblemsCompleted: 39,
                    sumOfStudentAverageScores: 17.5,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                  {
                    problemSetType: 'Cool-Down',
                    assignmentXrefs: ['wpiG6PSFf0aguy2P0bw9'],
                    totalNumUniqueProblemsAssigned: 1,
                    totalNumProblemsAssigned: 20,
                    totalNumProblemsCompleted: 16,
                    sumOfStudentAverageScores: 17,
                    numStudentsScored: 20,
                    studentAssesmentStats: generateStudentStats(3, 10),
                  },
                ],
              }, // End Teacher 2's class 2 module 3 lesson 3 stats
            ], // End Teacher 2's class 2 module 3 lesson stats list
          }, // End Teacher 2's class 2 module 3 stats
        ], // End Teacher 2's class 2 module stats list
      }, // End Teacher 2's class 2 stats
    ], // End Teacher 2 class stats list
  } as object); // End teacher 2's CurriculumStats
}
