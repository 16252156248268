import { render, staticRenderFns } from "./ProblemBuilder.vue?vue&type=template&id=267f8ac4&scoped=true&"
import script from "./ProblemBuilder.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProblemBuilder.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProblemBuilder.vue?vue&type=style&index=0&id=267f8ac4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267f8ac4",
  null
  
)

export default component.exports