import {
  ContentType,
  PersistableStateType,
  TransitionStateType,
} from '@/domain/Content';
import { ProblemDefinition } from '@/domain/Problem';

export function getContentType(ceri: string): ContentType | undefined {
  if (ceri.startsWith('WPR') || ceri.startsWith('PR')) {
    return ContentType.PROBLEM;
  } else if (ceri.startsWith('WPS') || ceri.startsWith('PS')) {
    return ContentType.PROBLEM_SET;
  } else if (ceri.startsWith('WTS') || ceri.startsWith('TS')) {
    return ContentType.TUTOR_STRATEGY;
  }
  return undefined;
}

export function getPersistableStateType(ceri?: string): PersistableStateType {
  if (ceri) {
    if (ceri.startsWith('W')) {
      return PersistableStateType.WORK_IN_PROGRESS;
    } else {
      return PersistableStateType.PUBLISHED;
    }
  } else {
    return PersistableStateType.NEW_ITEM;
  }
}

export function isWip(ceri: string): boolean {
  return getPersistableStateType(ceri) == PersistableStateType.WORK_IN_PROGRESS;
}

export function isPublished(ceri: string): boolean {
  return getPersistableStateType(ceri) == PersistableStateType.PUBLISHED;
}

export function getTransitionStateType(
  destination: string,
  source?: string
): TransitionStateType {
  const sourceState = getPersistableStateType(source);
  const destinationState = getPersistableStateType(destination);

  if (
    sourceState == PersistableStateType.NEW_ITEM &&
    destinationState == PersistableStateType.WORK_IN_PROGRESS
  ) {
    return TransitionStateType.NEW_TO_WIP;
  }

  if (
    sourceState == PersistableStateType.NEW_ITEM &&
    destinationState == PersistableStateType.PUBLISHED
  ) {
    return TransitionStateType.NEW_TO_PUB;
  }

  if (
    sourceState == PersistableStateType.WORK_IN_PROGRESS &&
    destinationState == PersistableStateType.WORK_IN_PROGRESS
  ) {
    return TransitionStateType.WIP_TO_WIP;
  }

  if (
    sourceState == PersistableStateType.WORK_IN_PROGRESS &&
    destinationState == PersistableStateType.PUBLISHED
  ) {
    return TransitionStateType.WIP_TO_PUB;
  }

  if (
    sourceState == PersistableStateType.PUBLISHED &&
    destinationState == PersistableStateType.WORK_IN_PROGRESS
  ) {
    return TransitionStateType.PUB_TO_WIP;
  }
  if (
    sourceState == PersistableStateType.PUBLISHED &&
    destinationState == PersistableStateType.PUBLISHED
  ) {
    return TransitionStateType.PUB_TO_PUB;
  }

  throw new Error(
    `Unknown transition from ${sourceState} to ${destinationState}`
  );
}

export function getTestModeStatus(problem?: ProblemDefinition): boolean {
  // Test mode is TRUE when both SHOW_CORRECTNESS & TUTORING_AVAILABLE are set to FALSE
  return (
    problem?.properties?.SHOW_CORRECTNESS === false &&
    problem?.properties?.TUTORING_AVAILABLE === false
  );
}

export function getChooseLabel(
  labelStart: string | number,
  index: number
): number | string | null {
  let start = null;
  let next = null;
  if (typeof labelStart == 'number') {
    start = Number(labelStart);
    next = start + index;
  } else if (typeof labelStart == 'string') {
    start = String(labelStart);
    next = String.fromCharCode(start.charCodeAt(0) + index);
  }
  return next;
}
