import { updateAttributes } from '@/api/core/attributes.api';
import { AttributeTargetType } from '@/domain/Attributes';
import { ProblemAttributes, ProblemDefinition } from '@/domain/Problem';
import store, { useStore } from '@/store';
import { isPublished } from '@/utils/builder.util';
import { problemsMap } from '@/composables/builder';
import { computed, ComputedRef, ref, Ref, set } from 'vue';
import { isEqual } from 'lodash';
import { notify } from './notify';
import dayjs from 'dayjs';

export function updateSkills(
  xref: string,
  skills: string[]
): Promise<ProblemAttributes> {
  return updateAttributes(AttributeTargetType.SDK3_PROBLEM, xref, {
    skill: skills,
  }).then(() => {
    const problem = problemsMap.value[xref];
    const attributes: ProblemAttributes = {
      ...(problem.attributes || {}),
      skill: skills,
    };
    store.commit('content/setProblem', {
      ...problem,
      attributes,
    });
    if (isPublished(problem.xref) && problem.mappedCeri) {
      const wip = problemsMap.value[problem.mappedCeri];
      if (wip) {
        store.commit('content/setProblem', {
          ...wip,
          attributes,
        });
      }
    }
    return attributes;
  });
}

interface ProblemBuilderLogic {
  localProblem: Ref<Partial<ProblemDefinition>>;
  saving: Ref<boolean>;
  modifiedFields: ComputedRef<Partial<ProblemDefinition>>;
  hasModifiedFields: ComputedRef<boolean>;
  updateProblem: () => Promise<void>;
}

export function useProblemBuilderLogic(
  problem: ProblemDefinition
): ProblemBuilderLogic {
  const store = useStore();

  const localProblem = ref<Partial<ProblemDefinition>>({});
  const saving = ref(false);

  const modifiedFields = computed((): Partial<ProblemDefinition> => {
    if (problem) {
      const local = localProblem.value;
      const prop = problem;
      const modified: Partial<ProblemDefinition> = {};
      for (const [key, value] of Object.entries(local as ProblemDefinition)) {
        const k = key as keyof ProblemDefinition;
        if (!isEqual(prop[k], value)) {
          modified[k] = value;
        }
      }
      return modified;
    } else {
      return { ...localProblem.value };
    }
  });

  const hasModifiedFields = computed(() => {
    return Object.keys(modifiedFields.value).length > 0;
  });

  function updateProblem(): Promise<void> {
    if (problem && hasModifiedFields.value) {
      saving.value = true;
      return store
        .dispatch('content/saveProblem', {
          xref: problem.xref,
          modifiedFields: modifiedFields.value,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            notify(`Failed to update Problem: ${error}`);
          } else if (xref) {
            const updatedAt = dayjs().valueOf();
            set(localProblem.value, 'updatedAt', updatedAt);
            notify(`Saved changes to Problem ${xref}.`);
          }
        })
        .catch(() => {
          notify('Something went wrong. Failed to update Problem.');
        })
        .finally(() => {
          saving.value = false;
        });
    } else {
      return Promise.resolve();
    }
  }

  return {
    localProblem,
    saving,
    modifiedFields,
    hasModifiedFields,
    updateProblem,
  };
}
