var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"search-container"},[_c('div',{staticClass:"d-flex flex-row align-center mb-4 mt-4"},[_c(_setup.CurriculaFilter,{staticClass:"mr-4",on:{"selectedCurriculaChanged":_setup.selectedCurriculaChanged}}),_c(_setup.ProblemTypeFilter,{on:{"selectedProblemTypeChanged":_setup.selectedProblemTypeChanged}})],1),(_vm.initializingProblems)?_vm._l((5),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"my-2",attrs:{"type":"card","height":"200px","width":"100%","data-cy":"search-result-skeleton"}})}):[_vm._l((_setup.searchResults),function(item){return _c('div',{key:item.xref,staticClass:"mb-4"},[(item.contentType === _setup.ContentType.PROBLEM)?_c(_setup.ProblemCard,{attrs:{"mode":_setup.Mode.BROWSE,"problem":item,"haveRedo":_setup.redoMap[item.xref] && _setup.redoMap[item.xref].length > 0,"options":[
            _setup.ProblemOptions.SAVE,
            _setup.ProblemOptions.PREVIEW,
            _setup.ProblemOptions.SUPPORTS,
            _setup.ProblemOptions.OPEN,
          ],"optionOverrides":{ [_setup.ProblemSetOptions.OPEN]: 'Go to Problem Set' }},on:{"input":function($event){return _setup.updateTree($event, item.xref)},"open":function($event){_setup.navigateToProblemSet = $event}},model:{value:(_setup.selectedProblems[item.xref]),callback:function ($$v) {_vm.$set(_setup.selectedProblems, item.xref, $$v)},expression:"selectedProblems[item.xref]"}}):_vm._e(),_c('v-card',[(item.contentType === _setup.ContentType.PROBLEM_SET)?_c(_setup.ProblemSetCardView,{attrs:{"mode":_setup.Mode.BROWSE,"problemSet":item,"value":_setup.selectedProblemSets[item.xref],"redoMap":_setup.redoMap,"psOptions":[_setup.ProblemSetOptions.PREVIEW, _setup.ProblemSetOptions.OPEN],"prOptions":[
              _setup.ProblemOptions.SAVE,
              _setup.ProblemOptions.PREVIEW,
              _setup.ProblemOptions.SUPPORTS,
            ],"optionOverrides":{
              [_setup.ProblemSetOptions.OPEN]: 'Go to Problem Set',
            }},on:{"input":function($event){return _setup.updateTree($event, item.xref)},"open":function($event){_setup.navigateToProblemSet = $event}}}):_vm._e()],1)],1)}),(_setup.isDownloadingProblems)?_c('div',{staticClass:"text-center ma-4"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):(_setup.nextPageToken)?_c(_setup.ScrollObserver,{on:{"visible":_setup.handleLoadMore}}):_vm._e()]],2),_c(_setup.AssignFab,{attrs:{"selectedTree":_setup.selectedTree,"totalProblems":_setup.numProblemsTotal}}),_c(_setup.GoToParentProblemSetDialog,{model:{value:(_setup.navigateToProblemSet),callback:function ($$v) {_setup.navigateToProblemSet=$$v},expression:"navigateToProblemSet"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }