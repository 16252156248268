var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.menuOptions.length)?_c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-cy":"problem-set-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_setup.showMenu),callback:function ($$v) {_setup.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticClass:"text-body-2"},[(_setup.menuOptions.includes(_setup.Options.EDIT))?_c('v-list-item',{attrs:{"to":{
          name: 'contentBuilder',
          params: {
            problemSetXref: _setup.problemSet.xref,
          },
          query: {
            ..._setup.route.query,
            mode: _setup.BuilderMode.EDIT,
            [_setup.RETURN_URL]: _setup.router.currentRoute.fullPath,
          },
        }}},[_vm._v(" Edit "+_vm._s(_setup.memberLabel)+" ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.OPEN))?_c('v-list-item',{attrs:{"data-cy":"open-problem-set-btn","to":_vm.openLocation},on:{"click":function($event){$event.preventDefault();return _setup.emit('open', _setup.problemSet.xref)}}},[_vm._v(" "+_vm._s(_vm.overrides[_setup.Options.OPEN] ?? 'Open contents')+" ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.UPDATE))?_c('v-list-item',{attrs:{"link":"","data-cy":"rename-problem-set-btn"},on:{"click":function($event){_setup.editDialog = true}}},[_vm._v(" Rename Problem Set ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":_setup.published},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn, attrs: tooltipAttrs }){return [_c('span',_vm._g(_vm._b({},'span',tooltipAttrs,false),tooltipOn),[(_setup.menuOptions.includes(_setup.Options.COPY))?_c(_setup.CopyProblemSetDialog,{attrs:{"data-cy":"copy-problem-set-btn","path":_vm.path,"sources":_vm.sources}}):_vm._e()],1)]}}],null,false,3781873351)},[_c('span',[_vm._v("Problem set must be published to copy")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_setup.published},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn, attrs: tooltipAttrs }){return [_c('span',_vm._g(_vm._b({},'span',tooltipAttrs,false),tooltipOn),[(_setup.menuOptions.includes(_setup.Options.DUPLICATE))?_c('v-list-item',{attrs:{"disabled":!_setup.published,"link":""},on:{"click":function($event){return _setup.openCopyDialog({
                  xref: _setup.problemSet.xref,
                  parent: _setup.parent?.xref,
                  replace: false,
                  currentPath: _setup.contentPath,
                })}}},[_vm._v(" Copy "+_vm._s(_setup.memberLabel)+" ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.REPLACE))?_c('v-list-item',{attrs:{"disabled":!_setup.published},on:{"click":function($event){return _setup.openCopyDialog({
                  xref: _setup.problemSet.xref,
                  parent: _setup.parent?.xref,
                  replace: true,
                  currentPath: _setup.contentPath,
                })}}},[_vm._v(" "+_vm._s(_setup.isExternalBuilderWithoutEdit ? `Edit ${_setup.memberLabel}` : `Replace ${_setup.memberLabel} with Copy`)+" ")]):_vm._e()],1)]}}],null,false,4294316886)},[_c('span',[_vm._v("Problem set must be published to copy")])]),(_setup.menuOptions.includes(_setup.Options.MOVE))?_c(_setup.MoveToFolderDialog,{attrs:{"path":_vm.path,"destinations":_vm.sources}}):_vm._e(),(_setup.menuOptions.includes(_setup.Options.ID))?_c(_setup.CopyTextDialog,{attrs:{"title":"Obtain ID","description":"Problem Set ID","content":_setup.problemSet.xref}}):_vm._e(),(_setup.menuOptions.includes(_setup.Options.PERMISSIONS))?_c(_setup.PermissionsDialog,{attrs:{"path":_vm.path}}):_vm._e(),(_setup.menuOptions.includes(_setup.Options.PREVIEW))?_c('v-list-item',{attrs:{"link":"","data-cy":"student-preview-btn","href":_setup.previewLink,"target":"blank"},on:{"click":_setup.trackPSPreviewAsStudent}},[_vm._v(" Preview As Student "),_c(_setup.PreviewInformationPopup)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":_setup.published},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_setup.menuOptions.includes(_setup.Options.PRINT))?_c('v-list-item',{attrs:{"data-cy":"print-btn","link":"","disabled":!_setup.published},on:{"click":function($event){return _setup.emit('print', {
                  ceri: _setup.problemSet.xref,
                  heading: _setup.problemSet.name,
                })}}},[_vm._v(" Print Problem Set ")]):_vm._e()],1)]}}],null,false,2372984133)},[_c('span',[_vm._v("Problem set must be published to print")])]),(_setup.menuOptions.includes(_setup.Options.REMOVE))?[_c('v-divider'),_c('v-list-item',{staticClass:"ps-action incorrect--text",attrs:{"link":"","data-cy":"delete-problem-set-btn"},on:{"click":function($event){_setup.removeDialog = true}}},[_vm._v(" Remove ")])]:_vm._e()],2)],1),_c(_setup.EditProblemSetDialog,{attrs:{"problemSet":_setup.problemSet},model:{value:(_setup.editDialog),callback:function ($$v) {_setup.editDialog=$$v},expression:"editDialog"}}),_c(_setup.RemoveMemberDialog,{attrs:{"member":_setup.problemSet,"parent":_setup.parent},on:{"remove":function($event){return _setup.emit('remove', $event)}},model:{value:(_setup.removeDialog),callback:function ($$v) {_setup.removeDialog=$$v},expression:"removeDialog"}}),_c(_setup.CopyProblemDialog,{attrs:{"copyData":_setup.copyData},on:{"close":function($event){_setup.showCopyDialog = false}},model:{value:(_setup.showCopyDialog),callback:function ($$v) {_setup.showCopyDialog=$$v},expression:"showCopyDialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }