import { render, staticRenderFns } from "./AssignmentReportParent.vue?vue&type=template&id=0a3e63b4&scoped=true&"
import script from "./AssignmentReportParent.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AssignmentReportParent.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3e63b4",
  null
  
)

export default component.exports