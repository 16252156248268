var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.menuOptions.length)?_c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","data-cy":"problem-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,971907502),model:{value:(_setup.showMenu),callback:function ($$v) {_setup.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[(_setup.menuOptions.includes(_setup.Options.SAVE))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_setup.saveToMyProblemSets = true}}},[_vm._v(" Save to My Problem Sets ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.PREVIEW))?_c('v-list-item',{attrs:{"link":"","href":_setup.previewLink,"target":"blank","data-cy":"student-preview-btn"},on:{"click":function($event){return _setup.emit('preview', _setup.problem.xref)}}},[_vm._v(" Preview As Student "),_c(_setup.PreviewInformationPopup)],1):_vm._e(),(_setup.menuOptions.includes(_setup.Options.SUPPORTS))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_setup.editStudentSupports = true}}},[_vm._v(" Edit Student Supports ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.OPEN))?_c('v-list-item',{attrs:{"link":"","data-cy":"go-to-problem-set-btn"},on:{"click":function($event){return _setup.emit('open', _setup.problem.xref)}}},[_vm._v(" Go to Problem Set ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.EDIT))?_c('v-list-item',{attrs:{"to":{
          name: 'contentBuilder',
          params: {
            ..._setup.route.params,
          },
          query: {
            ..._setup.route.query,
            mode: _setup.BuilderMode.EDIT,
          },
        }}},[_vm._v(" Edit ")]):_vm._e(),(_setup.problem?.xref.startsWith('W'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_setup.menuOptions.includes(_setup.Options.COPY))?_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Make a Copy ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.REPLACE))?_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" "+_vm._s(_setup.isExternalBuilderWithoutEdit ? 'Edit problem' : 'Replace with Copy')+" ")]):_vm._e()]}}],null,false,560869758)},[_c('span',[_vm._v("Problem "+_vm._s(_setup.problem?.xref)+" must be published to copy")])]):[(_setup.menuOptions.includes(_setup.Options.COPY))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.openCopyDialog({
              xref: _setup.problem?.xref,
              parent: _setup.parent?.xref,
              replace: false,
              currentPath: _setup.pathParam,
            })}}},[_vm._v(" Make a copy ")]):_vm._e(),(_setup.menuOptions.includes(_setup.Options.REPLACE))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.openCopyDialog({
              xref: _setup.problem?.xref,
              parent: _setup.parent?.xref,
              replace: true,
              currentPath: _setup.pathParam,
            })}}},[_vm._v(" "+_vm._s(_setup.isExternalBuilderWithoutEdit ? 'Edit problem' : 'Replace with Copy')+" ")]):_vm._e()],(_setup.menuOptions.includes(_setup.Options.STANDARDS))?_c(_setup.EditStandardsDialog,{attrs:{"target":_setup.problem,"parent":_setup.parent},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Edit Standards ")])]}}],null,false,2188367693)}):_vm._e(),(_setup.menuOptions.includes(_setup.Options.TEST_MODE))?_c('v-list-item',{staticClass:"d-flex justify-space-between align-center",on:{"click":function($event){return _setup.setTestMode(_setup.problem?.xref, !_setup.testMode)}}},[_vm._v(" Test Mode "),(_setup.testMode)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e(),(_setup.menuOptions.includes(_setup.Options.REMOVE))?[_c('v-divider'),_c('v-list-item',{staticClass:"incorrect--text",attrs:{"link":""},on:{"click":function($event){_setup.removeDialog = true}}},[_vm._v(" Remove ")])]:_vm._e()],2)],1),_c(_setup.SaveToMyProblemSetsDialog,{attrs:{"selectedTree":_setup.selectedTree},model:{value:(_setup.saveToMyProblemSets),callback:function ($$v) {_setup.saveToMyProblemSets=$$v},expression:"saveToMyProblemSets"}}),_c(_setup.StudentSupportsDialog,{attrs:{"problem":_setup.problem},model:{value:(_setup.editStudentSupports),callback:function ($$v) {_setup.editStudentSupports=$$v},expression:"editStudentSupports"}}),_c(_setup.CopyProblemDialog,{attrs:{"copyData":_setup.copyData},on:{"close":function($event){_setup.showCopyDialog = false}},model:{value:(_setup.showCopyDialog),callback:function ($$v) {_setup.showCopyDialog=$$v},expression:"showCopyDialog"}}),_c(_setup.RemoveMemberDialog,{attrs:{"member":_setup.problem,"parent":_setup.parent},on:{"remove":function($event){return _setup.emit('remove', $event)}},model:{value:(_setup.removeDialog),callback:function ($$v) {_setup.removeDialog=$$v},expression:"removeDialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }