var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isLoading || !_setup.rootProblemSet)?_c('v-row',{staticClass:"fill-height",attrs:{"align":"center"}},[(_setup.isLoading)?_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":"100","indeterminate":"","color":"primary"}})],1):_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v("Not Found")])])],1):_c('div',[_c(_setup.BuilderToolbar,{attrs:{"problemSet":_setup.rootProblemSet,"prXref":_setup.openedContent?.contentType == _setup.ContentType.PROBLEM
        ? _setup.openedContent.xref
        : undefined,"problemSetContext":_setup.problemSetContext},on:{"create-problem":function($event){return _setup.createProblem(_setup.rootPsXref)},"new":function($event){return _setup.addMemberToProblemSet(_setup.rootPsXref, $event)},"ps-edit":function($event){_setup.openEditPSDialog = true},"show-side-nav":_setup.toggleSideNav,"members":function($event){return _setup.addMemberToProblemSet(_setup.rootPsXref, $event)},"add-new-part":function($event){return _setup.createProblem($event)}},model:{value:(_setup.mode),callback:function ($$v) {_setup.mode=$$v},expression:"mode"}}),_c(_setup.UpdateProblemSetDialog,{attrs:{"problemSet":_setup.rootProblemSet},on:{"ps-removed":function($event){(_setup.selectedPath = null), (_setup.openEditPSDialog = false)}},model:{value:(_setup.openEditPSDialog),callback:function ($$v) {_setup.openEditPSDialog=$$v},expression:"openEditPSDialog"}}),_c('div',{style:({ 'margin-right': _setup.showSideNav ? `${_setup.sideNavWidthPx}px` : '0px' })},[(_setup.mode === _setup.BuilderMode.READ)?_c('div',[_c(_setup.ProblemSetCardView,{attrs:{"mode":_setup.Mode.EDIT,"problemSet":_setup.rootProblemSet,"elements":_setup.options,"enabled":!_setup.isPublishable && !_setup.isInternalBuilder,"psOptions":[
          _setup.ProblemSetOptions.EDIT,
          _setup.ProblemSetOptions.DUPLICATE,
          _setup.ProblemSetOptions.REPLACE,
          _setup.ProblemSetOptions.REMOVE,
        ],"prOptions":[
          _setup.ProblemOptions.EDIT,
          _setup.ProblemOptions.COPY,
          _setup.ProblemOptions.REPLACE,
          _setup.ProblemOptions.STANDARDS,
          _setup.ProblemOptions.TEST_MODE,
          _setup.ProblemOptions.REMOVE,
        ],"redoMap":_setup.redoMap,"pathLabelMap":_setup.pathLabelMap},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_setup.rootProblemSet)?_c('span',{staticClass:"text-h3",attrs:{"role":"heading"}},[(_setup.rootProblemSet.properties?.HEADER)?[_vm._v(" "+_vm._s(_setup.rootProblemSet.properties.HEADER)+" -- "+_vm._s(_setup.rootProblemSet.name)+" ")]:[_vm._v(" "+_vm._s(_setup.rootProblemSet.name)+" ")],_c('v-btn',{attrs:{"icon":"","color":"bluePrimary700"},on:{"click":function($event){_setup.openEditPSDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],2):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c(_setup.FilterOptions,{attrs:{"options":_setup.filterOptions,"outlined":true},model:{value:(_setup.options),callback:function ($$v) {_setup.options=$$v},expression:"options"}})]},proxy:true},{key:"append",fn:function(){return [(_setup.validationErrors.length)?_c('ul',{staticClass:"incorrect--text ma-4"},_vm._l((_setup.validationErrors),function(error,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()]},proxy:true},{key:"empty",fn:function(){return [_c(_setup.ProblemSetTypeView,{attrs:{"problemSet":_setup.rootProblemSet}})]},proxy:true}],null,false,1713517130),model:{value:(_setup.selectedTree),callback:function ($$v) {_setup.selectedTree=$$v},expression:"selectedTree"}}),(!_setup.isInternalBuilder)?_c(_setup.AssignFab,{attrs:{"psCeri":_setup.rootPsXref,"selectedTree":_setup.selectedTree}}):_vm._e()],1):_vm._e(),(_setup.mode == _setup.BuilderMode.EDIT && _setup.openedContent)?_c('div',{staticClass:"ma-6",attrs:{"id":_setup.selectedPath ?? undefined}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[(
                _setup.problemSetContext.problemSetType ==
                _setup.ProblemSetType.MULTI_PART_PROBLEM_SET
              )?[_c(_setup.ContentLabel,{attrs:{"ceri":_setup.pathLabelMap && !_setup.isEmpty(_setup.pathLabelMap)
                    ? _setup.pathLabelMap[_setup.problemSetContextPath]
                    : _setup.problemSetContext.xref,"classes":"text-h5","iconColor":"#FF7000"}}),(
                  !_setup.problemSetContext.permissions.includes(
                    _setup.AclPermissionType.UPDATE
                  )
                )?_c('span',[_vm._v(" (Read Only) ")]):_vm._e(),(_setup.contextTestMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"incorrect"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alpha-t-box ")])]}}],null,false,1801352325)},[_c('span',[_vm._v("Test Mode Enabled")])]):_vm._e()]:[_c(_setup.ContentLabel,{attrs:{"ceri":_setup.pathLabelMap && !_setup.isEmpty(_setup.pathLabelMap) && _setup.selectedPath
                    ? _setup.pathLabelMap[_setup.selectedPath]
                    : _setup.openedContent.xref,"showWipIcon":_setup.openedContent.xref.startsWith('W'),"classes":"text-h5","iconColor":"#FF7000"}}),(_setup.openedTestMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"incorrect"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alpha-t-box ")])]}}],null,false,1801352325)},[_c('span',[_vm._v("Test Mode Enabled")])]):_vm._e()],(_setup.showRedoIcon)?_c(_setup.RedoIcon):_vm._e(),(_setup.saving)?[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"neutral darken-1","size":24}}),_c('span',{staticClass:"mx-1"},[_vm._v("Saving...")])]:_vm._e()],2),(!_setup.saving)?_c(_setup.TimeFromNow,{attrs:{"updatedAt":_setup.openedContent.updatedAt,"classes":"text-caption neutral--text text--darken-2"}}):_vm._e()],1),(_setup.selectedPath)?_c(_setup.BuilderMenu,{attrs:{"contentPath":_setup.selectedPath,"selectedLabel":_setup.pathLabelMap && !_setup.isEmpty(_setup.pathLabelMap) && _setup.selectedPath
              ? _setup.pathLabelMap[_setup.selectedPath]
              : _setup.openedContent.xref},on:{"selected":function($event){_setup.selectedPath = $event},"add-new-part":function($event){return _setup.createProblem(_setup.problemSetContextPath)},"open-revert-dialog":_setup.openRevertPRDialog}}):_vm._e()],1),(
          _setup.problemSetContext.problemSetType ==
          _setup.ProblemSetType.MULTI_PART_PROBLEM_SET
        )?_c('div',{staticClass:"d-flex align-center justify-space-between mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","data-cy":"add-part","disabled":!_setup.problemSetContext.permissions.includes(
                  _setup.AclPermissionType.UPDATE
                )},on:{"click":function($event){return _setup.createProblem(_setup.problemSetContextPath)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3882509568)},[_c('span',[_vm._v("Add new problem part")])]),_c('v-tabs',{key:_setup.openedWipChildIndex,staticClass:"d-flex",attrs:{"background-color":"#fff8e0","show-arrows":""},model:{value:(_setup.openedWipChildIndex),callback:function ($$v) {_setup.openedWipChildIndex=$$v},expression:"openedWipChildIndex"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),(_setup.problemSetContextWipChildren.length)?[_c(_setup.draggable,{attrs:{"handle":".drag-handle"},on:{"end":_setup.onDragEnd}},[_c('transition-group',{staticClass:"d-flex align-center justify-center fill-height",attrs:{"enter-class":"drag-transition-enter"}},_vm._l((_setup.problemSetContextWipChildren),function(child){return _c('v-tab',{key:child,staticClass:"fill-height"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 drag-handle"},[_vm._v("mdi-drag")]),_c('div',{staticClass:"copyable-text"},[_c('div',[_c(_setup.ContentLabel,{attrs:{"ceri":_setup.pathLabelMap && !_setup.isEmpty(_setup.pathLabelMap)
                              ? _setup.pathLabelMap[
                                  `${_setup.problemSetContextPath},${child}`
                                ]
                              : child,"icon":false},scopedSlots:_vm._u([{key:"label",fn:function({ text, invalid }){return [_c('div',{class:{
                                'incorrect--text': invalid,
                                'black--text': !invalid,
                              }},[_vm._v(" "+_vm._s(_setup.pathLabelMap && !_setup.isEmpty(_setup.pathLabelMap) ? _setup.pathLabelMap[ _setup.problemSetContextPath + ',' + child ] : text)+" ")])]}}],null,true)})],1)])],1)])}),1)],1)]:_vm._e()],2)],1):_vm._e(),_c('v-divider',{staticClass:"my-4"}),(_setup.openedContent.contentType === _setup.ContentType.PROBLEM)?_c(_setup.ProblemBuilder,{key:_setup.openedContent.xref,attrs:{"problem":_setup.openedContent,"context":_setup.problemSetContext,"margin":_setup.showSideNav ? _setup.sideNavWidthPx : 0}}):_c(_setup.ProblemSetTypeView,{staticClass:"mt-16",attrs:{"problemSet":_setup.openedContent}})],1):_vm._e()]),_c(_setup.BuilderSideNav,{directives:[{name:"show",rawName:"v-show",value:(_setup.showSideNav),expression:"showSideNav"}],key:`${_setup.rootPsXref}:${_setup.mode}`,attrs:{"psXref":_setup.rootPsXref,"width":_setup.sideNavWidthPx,"show-ceri":_setup.showCeri},on:{"show-ceri":function($event){_setup.showCeri = $event || false}}}),(_setup.openedContent)?_c(_setup.RevertContentDialog,{attrs:{"ceri":_setup.openedContent.xref},model:{value:(_setup.showRevertProblemDialog),callback:function ($$v) {_setup.showRevertProblemDialog=$$v},expression:"showRevertProblemDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }