import { computed, Ref } from 'vue';
import { isContentAdminUser, isTrustedBuilderUser } from './global-auth';
import { useStore } from '@/store';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';
import { ContentType, IBuilderContent } from '@/domain/Content';
import { getContentType, isPublished, isWip } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';
import { ITutorStrategy } from '@/domain/Tutoring';
import { notify } from './notify';

export interface ProblemSetNode {
  xref: string;
  children?: ProblemSetNode[];
}

const store = useStore();

export const isInternalBuilder = computed((): boolean => {
  return isContentAdminUser.value || isTrustedBuilderUser.value;
});

export const isAdvancedBuilder = computed((): boolean => {
  const isAdvancedBuilderSetting =
    store.state.auth.user?.settings?.isAdvancedBuilder ?? false;
  return isInternalBuilder.value || (isAdvancedBuilderSetting as boolean);
});

export const problemsMap = computed((): Record<string, ProblemDefinition> => {
  return store.state.content.problemMap;
});

export const problemSetsMap = computed(
  (): Record<string, ProblemSetDefinition> => {
    return store.state.content.problemSetMap;
  }
);

export const tutorStrategiesMap = computed(
  (): Record<string, ITutorStrategy> => {
    return store.state.content.tutorStrategyMap;
  }
);

function getTarget(xref?: string): IBuilderContent | undefined {
  if (xref) {
    return (
      problemsMap.value[xref] ??
      problemSetsMap.value[xref] ??
      tutorStrategiesMap.value[xref]
    );
  }
  return undefined;
}

export function getWipTarget<T extends IBuilderContent>(
  xref: string
): T | undefined {
  const target = getTarget(xref);
  let wip = undefined;
  if (target?.permissions.includes(AclPermissionType.UPDATE)) {
    if (isWip(xref)) {
      wip = target;
    } else if (target.mappedCeri) {
      wip = getTarget(target.mappedCeri);
    }
  }
  return (wip ?? target) as T;
}

export function getPublishedTarget<T extends IBuilderContent>(
  xref: string
): T | undefined {
  const target = getTarget(xref);
  let published = undefined;
  if (isPublished(xref)) {
    published = target;
  } else if (target?.mappedCeri) {
    published = getTarget(target.mappedCeri);
  }
  return (published ?? target) as T;
}

// Nested Object, preserving structure.
export function getProblemSetStructure(xref?: string): ProblemSetNode[] {
  const res: ProblemSetNode[] = [];
  if (xref) {
    let wipPsIfAny = getWipTarget<ProblemSetDefinition>(xref);
    if (wipPsIfAny) {
      const children = wipPsIfAny.children ?? [];
      for (const child of children) {
        const childType = getContentType(child);
        switch (childType) {
          case ContentType.PROBLEM:
            {
              const wipPrIfAny = getWipTarget<ProblemDefinition>(child);
              if (wipPrIfAny) {
                res.push({ xref: wipPrIfAny.xref });
              }
            }
            break;
          case ContentType.PROBLEM_SET: {
            wipPsIfAny = getWipTarget<ProblemSetDefinition>(child);
            if (wipPsIfAny) {
              res.push({
                xref: wipPsIfAny.xref,
                children: getProblemSetStructure(wipPsIfAny.xref),
              });
            }
          }
        }
      }
    }
  }
  return res;
}

// TODO: Move to useProblemBuilderLogic.ts file instead? But this has propagation within
// Problem Set logic so not only pertaining to a Problem...
export function setTestMode(xref: string, value: boolean): void {
  let problems: ProblemDefinition[] = [];

  const type = getContentType(xref);
  if (type == ContentType.PROBLEM) {
    problems = [problemsMap.value[xref]];
  } else if (type == ContentType.PROBLEM_SET) {
    const children = problemSetsMap.value[xref].children ?? [];
    // FIXME: Figure out how we handle deeply-nested Problem Sets?
    problems = children
      .filter((child) => {
        getContentType(child) == ContentType.PROBLEM;
      })
      .map((child) => problemsMap.value[child]);
  }

  for (const problem of problems) {
    const properties = { ...problem.properties };

    // We do NOT want to show correctness or tutoring on the Problem if Test Mode is on.
    properties.SHOW_CORRECTNESS = !value;
    properties.TUTORING_AVAILABLE = !value;

    store
      .dispatch('content/saveProblem', {
        xref: xref,
        modifiedFields: { properties },
      })
      .catch(() => {
        notify(`Something went wrong. Failed to update Problem.`);
      });
  }
}

interface BuilderLogic {
  isPublishable: Ref<boolean>;
}

export function useBuilderLogic(xref?: string): BuilderLogic {
  function isPublishableTree(xref: string): boolean {
    const target = getTarget(xref);

    const publishable =
      target != undefined &&
      target.permissions.includes(AclPermissionType.UPDATE) &&
      (isWip(target.xref) || getTarget(target.mappedCeri) != undefined);

    const contentType = getContentType(xref);
    switch (contentType) {
      case ContentType.PROBLEM_SET: {
        const children =
          (target as unknown as ProblemSetDefinition)?.children ?? [];
        // return publishable && children.every((child) => isPublishableTree(child));
        return (
          publishable || children.some((child) => isPublishableTree(child))
        );
      }
      default:
        return publishable;
    }
  }

  // Have something is in WIP...
  // Does NOT require all supports to be published...
  const isPublishable = computed((): boolean => {
    let publishable = false;
    if (xref) {
      publishable = isPublishableTree(xref);
    }
    return publishable;
  });

  return {
    isPublishable,
  };
}
